/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Global, css } from '@emotion/core';
import minireset from 'emotion-minireset';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import store from './store';
import App from './app';
import * as serviceWorker from './service-worker';
import { LocalStorageProvider } from './common/provider/local-storage-provider';
import { QueueSettingsProvider } from './common/provider/queue-settings-provider';
import { OnlineContextProvider } from './common/online-context';

Sentry.init({
  dsn: 'https://9f8f2b590a584455b73943ce3dbaeb2c@o190418.ingest.sentry.io/6249086',
  enabled: process.env.NODE_ENV === 'production',
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

/**
 * There's no way that we can override the material styles without using
 * Material UI Theme Provider.
 */
const __BEWARE_FORCE_OVERRIDE_MATERIAL_STYLES = `
  .MuiPickersToolbar-toolbar, .MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockNumber-clockNumberSelected, .MuiPickersDay-daySelected {
    background-color: #2c9918 !important;
  }

  .MuiPickersClockPointer-thumb {
    background-color: #2c9918 !important;
    border: 14px solid #2c9918 !important;
  }

  .MuiButton-textPrimary, .MuiPickersDay-current, .MuiTypography-colorPrimary, .MuiPickersYear-root:focus {
    color: #2c9918 !important;
  }

  .MuiPickersDay-daySelected {
    color: #ffffff !important;
  }
`;

const globalStyles = [
  minireset,
  css`
    html {
      background: #000;
      color: #000;
      user-select: none;
    }
    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 20px;
    }
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    .force-ltr {
      html[dir='rtl'] & {
        direction: ltr;
        text-align: right;
      }
    }

    .PhoneInputInput {
      html[dir='rtl'] & {
        direction: ltr;
        text-align: right;
      }
    }

    .PhoneInputCountrySelectArrow {
      html[dir='rtl'] & {
        margin-right: 8px;
      }
    }

    ${__BEWARE_FORCE_OVERRIDE_MATERIAL_STYLES}
  `,
];

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StylesProvider jss={jss}>
        <Global styles={globalStyles} />
        <LocalStorageProvider>
          <QueueSettingsProvider>
            <OnlineContextProvider>
              <App />
            </OnlineContextProvider>
          </QueueSettingsProvider>
        </LocalStorageProvider>
      </StylesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/* eslint-enable */
