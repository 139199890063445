import { Queue } from '../../../store/types';

// This is a workaround for settings that current Console Queue Form can't handle
const getConfigFromRegistrationFields = <T = any>(configName: string, queue?: Queue): T | undefined => {
  const configList = (queue && queue?.registrationFields) || [];
  const config = configList.find((conf) => conf.name === configName);

  if (!config) {
    return undefined;
  }

  switch (configName) {
    case 'deviceId':
      return (config.value as any) as T;
    case 'enableFetchInventory':
      return ((config.value === 'true') as any) as T;
    case 'orderCategories':
      try {
        const data = JSON.parse(config.value as string);
        return data as T;
      } catch {
        return ([] as unknown) as T;
      }
    case 'enableStorageLicense':
      return (config.value as any) as T;
    default:
      return undefined;
  }
};

export default getConfigFromRegistrationFields;
