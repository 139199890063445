import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { getOnlineStatusSubscription } from '@ombori/grid-signals-react';
import useGridMobileInstallationResumedListener from './grid-mobile/use-grid-mobile-installation-resumed-listener';

const OnlineReactContext = React.createContext({
  isOnline: true,
});

interface OnlineContextProviderProps {
  children?: ReactNode;
}

const globalOnlineStatusSubscription = getOnlineStatusSubscription();

export const OnlineContextProvider = ({ children }: OnlineContextProviderProps) => {
  const [onlineContextValue, setOnlineContextValue] = useState({ isOnline: true });
  const { lastGridMobileResumedTimestamp } = useGridMobileInstallationResumedListener();

  useEffect(() => {
    globalOnlineStatusSubscription.start();

    return () => {
      globalOnlineStatusSubscription.stop();
    };
  }, [lastGridMobileResumedTimestamp]);

  useEffect(() => {
    const unsubscribe = globalOnlineStatusSubscription.subscribe((status) => {
      setOnlineContextValue({ isOnline: status.isOnline });
    });

    return () => {
      unsubscribe();
    };
  }, [setOnlineContextValue]);

  return <OnlineReactContext.Provider value={onlineContextValue}>{children}</OnlineReactContext.Provider>;
};

export const useOnlineContext = () => {
  return useContext(OnlineReactContext);
};
