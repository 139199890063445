import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { i18n } from 'i18next';
import * as Sentry from '@sentry/browser';
import { CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';
import { Helmet } from 'react-helmet';
import { createMuiTheme, ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
// @ts-ignore
import stylisRtl from 'stylis-rtl';
import initI18n from '../i18n';
import { Organization as OrganizationType } from '../store/types';
import { axios } from '../store/api';
import useDayjsTranslation from '../common/use-dayjs-translation';

const Queue = lazy(() => import('./routes/route-queue'));

const createTheme = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

export default function Organization() {
  const [i18nInt, setI18n] = useState<i18n | null>(null);
  const { organization: organizationName } = useParams<{ organization: string }>();
  const { path }: { path: string } = useRouteMatch();
  const [organization, setOrganization] = useState<OrganizationType | undefined>();

  const { changeLanguage: changeDayjsLanguage } = useDayjsTranslation();

  const initLocales = useCallback(async () => {
    if (!i18nInt) {
      try {
        const { data } = await axios.get<OrganizationType>(`/organizations/${organizationName}`);

        setOrganization(data);
      } catch (err) {
        // that's not expected, let's log it
        Sentry.captureException(err);
      }

      const i18nGlobalInstance = await initI18n();
      await changeDayjsLanguage(i18nGlobalInstance.language);

      setI18n(i18nGlobalInstance);
    }
  }, [changeDayjsLanguage, i18nInt, organizationName, setOrganization]);

  useEffect(() => {
    initLocales();
  }, [initLocales]);

  const language = i18nInt?.language ?? 'en';
  const dir = i18nInt?.dir() ?? 'ltr';

  const emotionCache = useMemo(() => {
    return createCache({ stylisPlugins: dir === 'rtl' ? [stylisRtl] : [] });
  }, [dir]);

  // TO DO: Create a theme and refactor styled components with individual color implementations
  //   to avoid redundant & nested color application
  const theme = useMemo(() => {
    return createTheme({
      direction: dir,
    });
  }, [dir]);

  if (!i18nInt) {
    return null;
  }

  if (!organization) {
    return null;
  }

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html dir={dir} lang={language} />
          {organization?.siteTitle && <title>{organization.siteTitle}</title>}
          {organization?.favicon && <link rel="icon" href={organization?.favicon} />}
        </Helmet>
        <Switch>
          <Route path={`${path}/queues/:queue`} component={Queue} />
        </Switch>
      </ThemeProvider>
    </CacheProvider>
  );
}
