import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { types as gridSignalTypes } from '@ombori/grid-signals-react';
import { Queue } from '../../../store/types';
import getConfigFromRegistrationFields from './get-config-from-registration-fields';

export interface TasksSettings {
  gridSignals?: gridSignalTypes.InitProps;
  deviceId?: string;
  enableFetchInventory?: boolean;
  orderCategories?: { [id: string]: string }[];
  enableStorageLicense?: boolean;
}

export interface QueueSettings extends TasksSettings {
  queue?: Queue;
  setQueueData: (queue: Queue) => void;
}

export const QueueSettingsContext = createContext<QueueSettings | undefined>(undefined);

interface QueueSettingsProviderProps {
  children: React.ReactNode;
  queue?: Queue;
}

const QueueSettingsProvider = (props: QueueSettingsProviderProps): JSX.Element => {
  const { children, queue } = props;

  const [queueData, setQueueData] = useState<Queue | undefined>(queue);

  useEffect(() => {
    if (queue) {
      setQueueData(queue);
    }
  }, [queue]);

  const handleSetQueueData = useCallback((data: Queue) => {
    setQueueData(data);
  }, []);

  const { deviceId, enableFetchInventory, orderCategories, enableStorageLicense } = useMemo(() => {
    return {
      deviceId: getConfigFromRegistrationFields<string>('deviceId', queueData) || '',
      enableFetchInventory: getConfigFromRegistrationFields<boolean>('enableFetchInventory', queueData) || false,
      orderCategories: getConfigFromRegistrationFields<{ [id: string]: string }[]>('orderCategories', queueData) || [],
      enableStorageLicense: getConfigFromRegistrationFields<boolean>('enableStorageLicense', queueData) || false,
    };
  }, [queueData]);

  const value = {
    queue: queueData,
    setQueueData: handleSetQueueData,
    deviceId,
    enableFetchInventory,
    orderCategories,
    enableStorageLicense,
  };

  return <QueueSettingsContext.Provider value={value}>{children}</QueueSettingsContext.Provider>;
};

export default QueueSettingsProvider;
