import { useCallback, useState } from 'react';

interface UseDayjsTranslationResponse {
  changeLanguage: (lang: string) => Promise<void>;
}

const useDayjsTranslation = (): UseDayjsTranslationResponse => {
  const [languages, setLanguages] = useState<string[]>([]);

  const changeLanguage = useCallback(
    async (lang: string) => {
      try {
        if (languages.includes(lang)) {
          return;
        }

        const langCode = lang.substring(0, 2).toLocaleLowerCase();

        await import(`dayjs/locale/${langCode}.js`);

        setLanguages((prevState) => {
          prevState.push(lang);
          return prevState;
        });
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [languages],
  );

  return { changeLanguage };
};

export default useDayjsTranslation;
