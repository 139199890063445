import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const initI18n = async () => {
  await i18n
    // https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    // https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',
      load: 'languageOnly',
      interpolation: { escapeValue: false }, // not needed for react as it escapes by default
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        order: ['querystring', 'navigator'],
        caches: [],
      },
      react: {
        useSuspense: false,
      },
    });

  return i18n;
};

export default initI18n;
