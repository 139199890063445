import React, { createContext, useState, useCallback } from 'react';

export interface LocalStorage<T = any> {
  data: T;
  setPrefixKey: (prefixKey: string) => void;
  load: (prefixKey?: string) => void;
  save: (itemKey: string, value: string) => void;
}

export const LocalStorageContext = createContext<LocalStorage | undefined>(undefined);

interface LocalStorageProviderProps {
  children: React.ReactNode;
  key?: string;
}

const getLocallyStoredItemsByKeySearch = (primaryKey: string): { [itemKey: string]: string }[] => {
  const data = Object.keys(localStorage)
    .filter((key) => new RegExp(primaryKey).test(key))
    .map((key) => {
      const updatedKey = key.replace(`${primaryKey}-`, '');

      return { [updatedKey]: localStorage.getItem(key) || '' };
    });

  return data;
};

const LocalStorageProvider = (props: LocalStorageProviderProps): JSX.Element => {
  const { children, key } = props;

  const [mainKey, setMainKey] = useState(key);
  const [localData, setLocalData] = useState<{ [itemKey: string]: string }>({});

  const handleLoadItems = useCallback(
    (prefixKey?: string) => {
      const mainStorageKey = mainKey || prefixKey;

      if (!mainStorageKey) {
        return;
      }

      const data = getLocallyStoredItemsByKeySearch(mainStorageKey);
      const values = data.reduce((obj, item) => {
        return { ...obj, ...item };
      }, {});

      setLocalData(values);
    },
    [mainKey],
  );

  const handleSetPrefixKey = useCallback((prefixKey: string) => setMainKey(prefixKey), [setMainKey]);

  // Can only handle string value for now
  const handleSetItem = useCallback(
    (itemKey: string, value: string) => {
      const fullKey = `${mainKey}-${itemKey}`;

      setLocalData((values) => {
        return { ...values, [itemKey]: value };
      });

      localStorage.setItem(fullKey, value);
    },
    [mainKey],
  );

  const value = {
    data: localData,
    setPrefixKey: handleSetPrefixKey,
    load: handleLoadItems,
    save: handleSetItem,
  };

  return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>;
};

export default LocalStorageProvider;
