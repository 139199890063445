import { useContext, useEffect } from 'react';
import { Queue } from '../../../store/types';
import { QueueSettings, QueueSettingsContext } from './queue-settings-provider';

const useQueueSettings = (queue?: Queue): QueueSettings => {
  const ctx = useContext(QueueSettingsContext);

  useEffect(() => {
    if (queue) {
      ctx?.setQueueData(queue);
    }
  }, [ctx, queue]);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming QueueSettingsContext context. Make sure you wrap the component inside the "QueueSettingsContext".',
    );
  }

  return ctx;
};

export default useQueueSettings;
