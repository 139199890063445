import { useContext, useEffect } from 'react';
import { LocalStorage, LocalStorageContext } from './local-storage-provider';

const useLocalStorage = <T = any>(prefixKey?: string): LocalStorage<T> => {
  const ctx = useContext(LocalStorageContext);

  useEffect(() => {
    if (prefixKey) {
      ctx?.setPrefixKey(prefixKey);
    }
  }, [ctx, prefixKey]);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming LocalSettingsContext context. Make sure you wrap the component inside the "LocalSettingsContext".',
    );
  }

  return ctx;
};

export default useLocalStorage;
