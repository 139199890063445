import { useState } from 'react';
import useGridMobileEventListener, { GridMobileEventType } from './use-grid-mobile-event-listener';

const useGridMobileInstallationResumedListener = (handler?: () => void) => {
  const [lastGridMobileResumedTimestamp, setLastGridMobileResumedTimestamp] = useState(0);

  useGridMobileEventListener(GridMobileEventType.InstallationResumed, () => {
    setLastGridMobileResumedTimestamp(Date.now());
    if (handler) {
      handler();
    }
  });

  return { lastGridMobileResumedTimestamp };
};

export default useGridMobileInstallationResumedListener;
