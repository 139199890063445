import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

let baseIdentityURLBase = '';
switch (window.location.hostname) {
  case 'localhost':
  case 'tasks-dev.griddeveloper.com':
    baseIdentityURLBase = 'https://id.griddeveloper.com/';
    break;
  case 'tasks-qa.omborigrid.com':
    baseIdentityURLBase = 'https://id-qa.ombori.com/';
    break;
  case 'tasks-in.omborigrid.com':
  case 'tasks-eu.omborigrid.com':
  case 'tasks-au.omborigrid.com':
  case 'tasks-uae.omborigrid.com':
  case 'tasks-us.omborigrid.com':
  default:
    baseIdentityURLBase = 'https://id.ombori.com/';
    break;
}

export const baseIdentityURL = baseIdentityURLBase;

export function getClientSettings(): UserManagerSettings {
  const { origin } = window.location;
  return {
    authority: baseIdentityURL,
    client_id: 'tasks-app',
    redirect_uri: `${origin}/auth-callback`,
    post_logout_redirect_uri: `${origin}/logout-callback`,
    response_type: 'code',
    scope: 'openid email',
    filterProtocolClaims: true,
    stateStore: new WebStorageStateStore({ prefix: 'oidc', store: localStorage }),
    userStore: new WebStorageStateStore({ prefix: 'oidc', store: localStorage }),
  };
}

const manager = new UserManager(getClientSettings());

export default manager;
